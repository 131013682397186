export default class UtilitarioGeral {
  static valorValido(valor: any):boolean {
    if (valor === undefined || valor === null || valor === '') {
      return false;
    }
    return true;
  }

  static validaValorComLimiteCaracteres(valor: any, limiteCaracteres: number):boolean {
    if (!this.valorValido(valor)) {
      return false;
    }
    if (valor.length === limiteCaracteres) {
      return true;
    }
    return false;
  }

  static objetoValido(objeto: any):boolean {
    if (objeto === undefined || objeto === null || objeto === '') {
      return false;
    }

    if (objeto) {
      return true;
    }

    return false;
  }

  static validaBoleano(valor: any):boolean {
    if (valor === undefined || valor === null) {
      return false;
    }

    return true;
  }

  static validaCodigo(valor: any):boolean {
    if (valor === undefined || valor === null) {
      return false;
    }

    if (valor > 0) { return true; }

    return false;
  }

  /**
 * Cria instância local para o objeto com os mesmos valores
 * porém removendo as referências e impactos visuais na tela
 */
  static instanciaObjetoLocal(objeto: any):any {
    return JSON.parse(JSON.stringify(objeto));
  }

  static validaLista(valor: any):boolean {
    if (valor === undefined || valor === null) {
      return false;
    }

    if (valor.length > 0) {
      return true;
    }
    return false;
  }

  static converterValorNumericoJson(valor: any):number {
    if (this.valorValido(valor)) {
      const valorConvertido = valor.replace('.', '').replace(',', '.');
      return valorConvertido;
    }
    return 0;
  }

  static montaExibicaoEmpresa(codigoExibicao: string, nomeExibicao: string):string {
    let exibicaoEmpresa = '';
    if (this.valorValido(codigoExibicao)) {
      exibicaoEmpresa += `${codigoExibicao} - `;
    }
    exibicaoEmpresa += nomeExibicao;
    return exibicaoEmpresa;
  }

  static primeiraLetraMaiuscula(texto: string):string {
    if (this.valorValido(texto)) {
      if (texto.length > 1) {
        return texto && texto[0].toUpperCase() + texto.slice(1).toLowerCase();
      }
      return texto.toUpperCase();
    }
    return '';
  }

  static documentoIdentificacaoCPFouCNPJ(cpfOuCnpj: string):string {
    let texto = '';
    if (cpfOuCnpj === undefined || cpfOuCnpj === null || cpfOuCnpj === '') {
      return '';
    }

    if (cpfOuCnpj.length === 11) {
      texto = 'CPF';
    } if (cpfOuCnpj.length === 14) {
      texto = 'CNPJ';
    }

    return texto;
  }

  static obterApenasNumeros(valor: string):string {
    const valorNovo = valor.replace(/[^0-9]/g, '');
    return valorNovo;
  }
}
