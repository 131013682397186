import { reactive, readonly } from 'vue';
import { IConfiguracaoApp, IConfiguracaoAppParceiro } from '@/core/models/IConfiguracaoApp';
import UtilitarioGeral from '@/core/utilitarios/UtilitarioGeral';
import { IUsuario } from '@/models/Entidades/IUsuario';
import { IDTOUsuarioMeusDados } from '@/models/DTO/Usuarios/IDTOUsuarioMeusDados';
import { IConfiguracao } from '@/models/Entidades/IConfiguracao';

const state = reactive({
  configuracaoApp: {} as IConfiguracaoApp,
  parceiroAutenticado: {} as IConfiguracaoAppParceiro,
  usuarioAutenticado: {} as IUsuario,
  configuracaoPlataforma: {} as IConfiguracao,
  carregando: false,
});

const getters = {
  dadosIdentificacaoEmpresa():string {
    return UtilitarioGeral.montaExibicaoEmpresa('1', 'TESTE');
  },
  obterParceiroAutenticado(codigoParceiro: string):IConfiguracaoAppParceiro {
    const parceiroAutenticado = state.configuracaoApp.parceiros.find((c) => c.codigo === codigoParceiro);
    if (parceiroAutenticado !== undefined) {
      return parceiroAutenticado;
    }
    return {} as IConfiguracaoAppParceiro;
  },
  obterParceiroPorUrl(url: string):IConfiguracaoAppParceiro {
    let parceiroAutenticado: IConfiguracaoAppParceiro = {} as IConfiguracaoAppParceiro;

    for (let index = 0; index < state.configuracaoApp.parceiros.length; index += 1) {
      const urlIdentificada = state.configuracaoApp.parceiros[index].urls.find((c) => c === url);
      if (UtilitarioGeral.valorValido(urlIdentificada)) {
        parceiroAutenticado = state.configuracaoApp.parceiros[index];
        break;
      }
    }
    return parceiroAutenticado;
  },
  nomeUsuarioAutenticado():string {
    return state.usuarioAutenticado.nome;
  },
  primeiraLetraUsuarioAutenticado():string {
    if (UtilitarioGeral.objetoValido(state.usuarioAutenticado)) {
      if (UtilitarioGeral.valorValido(state.usuarioAutenticado.nome)) {
        return state.usuarioAutenticado.nome.charAt(0);
      }
    }
    return '';
  },
};

const mutations = {
  atualizarCarregando(valor:boolean):void {
    state.carregando = valor;
  },
  atualizarConfiguracaoApp(configuracaoApp: IConfiguracaoApp):void {
    state.configuracaoApp = configuracaoApp;
  },
  atualizarParceiroAutenticado(parceiroAutenticado: IConfiguracaoAppParceiro):void {
    state.parceiroAutenticado = parceiroAutenticado;
  },
  atualizarUsuarioAutenticado(usuarioAutenticado: IUsuario):void {
    state.usuarioAutenticado = usuarioAutenticado;
  },
  atualizarConfiguracaoPlataforma(configuracaoPlataforma: IConfiguracao):void {
    state.configuracaoPlataforma = configuracaoPlataforma;
  },
  atualizarUsuarioAutenticadoMeusDados(meusDados: IDTOUsuarioMeusDados):void {
    state.usuarioAutenticado.nome = meusDados.nome;
    state.usuarioAutenticado.email = meusDados.email;
    state.usuarioAutenticado.senha = meusDados.senha;
    state.usuarioAutenticado.telefone = meusDados.telefone;
  },
};

const actions = {
};

export default {
  state: readonly(state),
  getters,
  mutations,
  actions,
};
